// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --iotify-new-themes: #a8b5a9;
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  --ion-color-btn:#4bd894;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

   /** darknew **/
   --ion-color-darknew: #39354e;
   --ion-color-darknew-rgb: 56, 42, 120;
   --ion-color-darknew-contrast: #000000;
   --ion-color-darknew-contrast-rgb: 0, 0, 0;
   --ion-color-darknew-shade: #a593f6;
   --ion-color-darknew-tint: #4667c9;


    /** transoaren **/
  --ion-color-transparent: #a4a7c900;
  --ion-color-transparent-rgb: 82, 96, 255;
  --ion-color-transparent-contrast: #ffffff;
  --ion-color-transparent-contrast-rgb: 255, 255, 255;
  --ion-color-transparent-shade: #3e4ad6d2;
  --ion-color-transparent-tint: #6370ff;

  //custom
  --ion-color-materialblue:#e7eef8;
  --ion-color-materialbluedark:#a5bfd5;
  --ion-color-gradient: #34c483;

  //custom new iot.remon variables
  --iot-backgroundPrimary:#150464;
  --iot-backgroundSecondary:#24034b;
  --iot-backgroundTertiary:#37022e;
  --iot-backgroundContentGrid: #2716768f;   //#271676;
  --iot-backgroundContentFont:#eae9f1;
  --iot-backgroundHover:#382a78;
  --iot-backgroundSelected:#382a78;
  --iot-fontContentSize:#382a78;
  --iot-fontColor:white;
  --iot-borderThemeP:#4e4087;
  --iot-borderThemeS:#4e4087;
  --iot-borderThemeT:#4e4087;
  --iot-contentbackground:#fff;

  //oil field
  --iot-headerFontColor: white;
  --iot-contentFontColor: white;

//iot spcial 
//  --iot-innerContentBg:#0155d2 ;
 --iot-innerContentBg:#2e1a8c ;
 
  --iot-primaryColor:#4e4087;
  --iot-primaryFontColor:#ffffff;
//iot spcial
  //  --iot-loginbackground:'ems.remon.in.png';
   --header-margin:43px;
   --widgets-bg:#ffffff3d;
  // --iot-loginbackground:url('../../assets/img/grid/ems.remon.in.jpg')center center / cover no-repeat fixed;
//font = style variables
  --ion-header-font: 'Trebuchet MS', 'Verdana', 'sans-serif';
  --ion-header-size: 0.9rem;
  --ion-content-font: 'Trebuchet MS', 'Verdana', 'sans-serif';
  --ion-content-size: 0.825rem;
  --ems-text-color:white;
  --ems-header-color:#453394;
  --iot-headerFontColor: #ff9800;

  --jqx-widget-tree-color: black;
  --jqx-widget-tree-backgroundcolor: white;
  //this variables spcl for error in sensor do not use other places
  // --ems-error-color:#fff;
}
